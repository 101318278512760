// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-announcements-js": () => import("./../../../src/pages/announcements.js" /* webpackChunkName: "component---src-pages-announcements-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-term-of-use-js": () => import("./../../../src/pages/term-of-use.js" /* webpackChunkName: "component---src-pages-term-of-use-js" */),
  "component---src-templates-announcement-page-index-js": () => import("./../../../src/templates/AnnouncementPage/index.js" /* webpackChunkName: "component---src-templates-announcement-page-index-js" */),
  "component---src-templates-character-page-index-js": () => import("./../../../src/templates/CharacterPage/index.js" /* webpackChunkName: "component---src-templates-character-page-index-js" */)
}

